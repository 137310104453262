import { ENV_NAME } from "./envName"
import * as CONSTANTS_VALUES from "./values"

export const SMOOTH_LINE_API_URL = CONSTANTS_VALUES.SMOOTH_LINE_API_URLS[ENV_NAME]
export const SMOOTH_API_URL = "/smooth-api"
export const SMOOTH_SMOOTHER_API_URL = SMOOTH_LINE_API_URL + "/base_api/smoother"
export const FIREBASE_PROPS = CONSTANTS_VALUES.FIREBASE_PROPS[ENV_NAME]
export const V1_OPE_URL = CONSTANTS_VALUES.V1_OPE_URLS[ENV_NAME]
export const RESTRICTED_ADMIN_URL = CONSTANTS_VALUES.RESTRICTED_ADMIN_URLS[ENV_NAME]
export const IS_NEXT = process.env.IS_NEXT
export const GOOGLE_OIDC_COOKIE_KEY = "forCS_loginToken"
export const GOOGLE_OIDC_REFRESH_TOKEN_KEY = "forCS_refreshToken"
// export const IS_NEXT = true

export const LIFF_URL = CONSTANTS_VALUES.LIFF_URLS[ENV_NAME]
